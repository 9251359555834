import { Component, Vue } from "vue-property-decorator";
import UlContentHeader from "../../components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import UIDialogDelete from "@/components/UlDialogDelete.vue";
import { SORT_ORDER_DESC, TableOptions } from "@/api/request";
import { DeleteRequest, SearchRequest } from "@/api/user/request";
import UserShopGet from "@/store/user-shop/get";
import UserSearch, { ListItem } from "@/store/user/search";
import UserDelete from "@/store/user/delete";
import Flash, { ErrorAlert, SuccessAlert } from "@/store/common/flash";

@Component({
  components: { UlContentHeader, UlBreadcrumbs, UIDataTable, UIDialogDelete }
})
export default class index extends Vue {
  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "ユーザー情報一覧";
  headingSub = "Customer information list";
  breadCrumbs = [
    { text: "カスタマー", disabled: true },
    { text: "ユーザー情報一覧", disabled: true }
  ];

  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  tableHeaders = [
    { text: "店舗ID", value: "shopId" },
    { text: "店舗名", value: "shopName", sortable: false },
    { text: "ユーザーID", value: "userId" },
    { text: "名前", value: "userName" },
    { text: "生年月日", value: "birthday" },
    {
      label: "修正",
      text: "",
      value: "edit",
      sortable: false
    },
    {
      label: "削除",
      text: "",
      value: "delete",
      sortable: false
    }
  ];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  // ------------

  // ------------
  // 変動値
  // ------------

  // 削除ダイアログの表示有無
  showDialog = false;

  // 削除されるユーザー名
  deletingUserName = "";

  // 削除されるユーザーID
  deletingUserId = "";

  // ローディングステータス
  isLoading = false;

  // 検索入力オプション
  inputOptions = {
    searchShopId: null as number | null,
    selectShopIds: null as number[] | null,
    userId: null as number | null,
    userName: null as string | null
  };

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions: TableOptions = {
    page: 1,
    displayCount: 20,
    sortKey: "shopId",
    sortOrder: SORT_ORDER_DESC
  };

  // ------------

  /**
   * テーブルに表示するアイテムリスト（UIDataTableコンポーネントに渡す）
   */
  get tableItems() {
    return UserSearch.getItems;
  }

  /**
   * 総件数（UIDataTableコンポーネントに渡す）
   */
  get totalCount() {
    return UserSearch.getTotalCount;
  }

  get shopItems() {
    return UserShopGet.getItems;
  }

  /**
   * createdライフサイクルフック（UIDataTableコンポーネントに渡す）
   */
  async created() {
    await UserSearch.restore(this.inputOptions as SearchRequest);
    const request = UserSearch.getSearchRequest;
    // 検索入力オプション
    request.searchShopId &&
      (this.inputOptions.searchShopId = request.searchShopId);
    request.selectShopIds &&
      (this.inputOptions.selectShopIds = request.selectShopIds);
    request.userId && (this.inputOptions.userId = request.userId);
    request.userName && (this.inputOptions.userName = request.userName);

    // 検索テーブルオプション
    request.page && (this.tableOptions = request);
    await this.search();
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await UserSearch.clearResponse();
    await UserShopGet.clearResponse();
    await UserDelete.clearResponse();
  }

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async tableChangeCallback(tableOptions: TableOptions) {
    // 子コンポーネントへpagingOptionsの変更が通知される
    this.tableOptions = tableOptions;
    if (this.tableOptions.sortKey == null) {
      this.tableOptions.sortKey = "shopId";
    }
    await this.search();
  }

  /**
   * 検索ボタンがクリックされた際のコールバック
   */
  async searchClickCallback() {
    await Flash.clear();
    await this.search();
  }

  /**
   * テーブル行のアクションボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  async actionClickCallback(item: ListItem) {}

  /**
   * テーブル行の編集ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  async editClickCallback(item: ListItem) {
    await this.$router.push({
      name: "customer-edit",
      params: { id: String(item.userId) }
    });
  }

  /**
   * テーブル行の削除ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  deleteClickCallback(item: ListItem) {
    this.showDialog = true;
    this.deletingUserId = String(item.userId);
    this.deletingUserName = item.userName;
  }

  /**
   * ダイアログの削除のコールバック
   */
  async doDeleteOnDialog() {
    await Flash.clear();
    this.showDialog = false;
    if (!this.deletingUserId) {
      return;
    }

    this.isLoading = true;
    await UserDelete.deleteOne({
      id: this.deletingUserId
    } as DeleteRequest);
    if (UserDelete.isSuccess) {
      await Flash.setSuccessNow({
        message: "ユーザーを削除しました。",
        consumePath: ""
      } as SuccessAlert);
      await this.search();
    } else {
      await Flash.setErrorNow({
        message: UserDelete.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  /**
   * 店舗情報の一覧を取得する処理
   */
  async fetchShopList(): Promise<boolean> {
    if (UserShopGet.isSuccess) {
      return true;
    }

    await UserShopGet.get();
    if (!UserShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: UserShopGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    return UserShopGet.isSuccess;
  }

  /**
   * 検索処理
   */
  async search() {
    this.isLoading = true;
    let isSuccess = await this.fetchShopList();
    if (!isSuccess) {
      this.isLoading = false;
      return;
    }

    let request = this.createRequest();
    await UserSearch.search(request);
    if (!UserSearch.isSuccess) {
      await Flash.setErrorNow({
        message: UserSearch.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  goToDownload() {
    this.$router.push({ name: "customer-download" }).then();
  }

  /**
   * dataから検索用のリクエストを作成する
   */
  private createRequest(): SearchRequest {
    const request = this.tableOptions as SearchRequest;
    // NEW_DEV-1318 cyber start
    if (this.inputOptions.searchShopId) {
      request.searchShopId = this.inputOptions.searchShopId;
    } else {
      request.searchShopId = null;
    }

    if (this.inputOptions.selectShopIds) {
      request.selectShopIds = this.inputOptions.selectShopIds;
    } else {
      request.selectShopIds = null;
    }

    if (this.inputOptions.userId) {
      request.userId = this.inputOptions.userId;
    } else {
      request.userId = null;
    }

    if (this.inputOptions.userName) {
      request.userName = this.inputOptions.userName;
    } else {
      request.userName = null;
    }

    request.searchType = 'customer';
    // NEW_DEV-1318 cyber end
    return request;
  }
}
